import { axios } from '@/utils/request'
import { modules } from './index'

export function getDeptTreeData(parentCode) {
  return axios({
    url: `${modules.agency}/1/departments/tree?parentCode=${parentCode}`,
    method: 'get'
  })
}

export function getDeptPage(parameter) {
  return axios({
    url: `${modules.agency}/1/departments/page`,
    method: 'get',
    params: parameter
  })
}

export function validateDeptCode(departmentCode, id = '') {
  return axios({
    url: `${modules.agency}/1/departments/departmentCode/available?departmentCode=${departmentCode}&id=${id}`,
    method: 'get'
  })
}

export function validateDeptName(departmentName, id = '') {
  return axios({
    url: `${modules.agency}/1/departments/departmentName/available?departmentName=${departmentName}&id=${id}`,
    method: 'get'
  })
}

export function createDept(data) {
  return axios({
    url: `${modules.agency}/1/departments`,
    method: 'post',
    data
  })
}

export function updateDept(departmentCode, data) {
  return axios({
    url: `${modules.agency}/1/departments/${departmentCode}`,
    method: 'put',
    data
  })
}

export function deleteDept(data) {
  return axios({
    url: `${modules.agency}/1/departments`,
    method: 'delete',
    data
  })
}
