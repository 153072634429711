const api = {
  Login: '/auth/login',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info'
}

const modules = {
  security: 'freeapis-web-security',
  systemctl: 'freeapis-web-systemctl',
  notification: 'freeapis-web-notification',
  asi: 'freeapis-web-asi',
  agency: 'freeapis-web-agency',
  publicuser: 'freeapis-web-publicuser',
  product: 'freeapis-web-product',
  business: 'freeapis-web-business',
  storage: 'freeapis-web-storage',
  stock: 'freeapis-web-stock',
  supplier: 'freeapis-web-supplier',
  offer: 'freeapis-web-offer',
  enterprise: 'freeapis-web-enterprise',
  bridge: 'freeapis-web-bridge',
  log: 'logger-server',
  cms: 'freeapis-web-cms',
  crm: 'freeapis-web-crm',
  activiti: 'freeapis-web-activiti',
  report: 'freeapis-web-report'
}
export {
  api, modules
}
