<template>
  <a-card :bordered="false">
    <a-row>
      <a-col :span="6">
        <a-tree showLine :treeData="treeData"
                :defaultSelectedKeys="['-1']"
                :expandedKeys="['-1']" @select="handleTreeSelect"
                :replaceFields="{ title: 'departmentName', key: 'departmentCode' }"/>
      </a-col>
      <a-col :span="18">
        <div class="table-operator">
          <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        </div>
        <s-table size="default" rowKey="sequenceNBR"
                 ref="table" childrenColumnName="extend1"
                 :columns="columns" :data="loadData"
                 @dblclick="handleEdit">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <span slot="lockStatus" slot-scope="text">
            {{ text === 'N' ? '启用' : '禁用' }}
          </span>
          <span slot="action" slot-scope="text, record, index">
            <template>
              <a @click="handleEdit(record, index)">编辑</a>
              <a-divider type="vertical" />
              <a @click="handleDelete(record, index)">删除</a>
            </template>
          </span>
        </s-table>
      </a-col>
    </a-row>
    <a-modal :width="640" :visible="formVisible"
             :confirmLoading="confirmLoading" :title="modalFlag === 'create' ? '创建部门' : '编辑部门'"
             @ok="handleOk" @cancel="formVisible = false">
      <a-form :form="form">
        <a-form-item label="部门编码" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input :disabled="modalFlag === 'update'" v-decorator="['departmentCode', {rules:[{required: true, message: '请输入部门编码'}, {validator: checkDeptCode}]}]" />
        </a-form-item>
        <a-form-item label="部门名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['departmentName', {rules:[{required: true, message: '请输入部门名称'}]}]" />
        </a-form-item>
        <a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['description', {initialValue: ''}]" :autosize="{ minRows: 2 }"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { getDeptTreeData, getDeptPage, validateDeptCode, createDept, updateDept, deleteDept } from '@/api/dept'
export default {
  components: {
    STable
  },
  data() {
    return {
      treeData: [],
      queryParam: { parentCode: '' },
      columns: [{
        title: '#',
        scopedSlots: { customRender: 'serial' }
      }, {
        title: '部门编码',
        dataIndex: 'departmentCode'
      }, {
        title: '部门名称',
        dataIndex: 'departmentName'
      }, {
        title: '锁定状态',
        dataIndex: 'lockStatus'
      }, {
        title: '说明',
        dataIndex: 'description'
      }, {
        title: '操作',
        dataIndex: 'action',
        width: '150px',
        scopedSlots: { customRender: 'action' }
      }],
      loadData: parameter => {
        if (!parameter.orderby) {
          parameter.order = 'desc'
          parameter.orderby = 'recDate'
        }
        return getDeptPage(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      form: this.$form.createForm(this),
      currentNodeData: null
    }
  },
  created() {
    getDeptTreeData(-1).then(v => {
      this.treeData = [v]
    })
  },
  methods: {
    handleTreeSelect(keys, e) {
      console.log(e)
      this.currentNodeData = e.node.dataRef
      // e.node.dataRef.children = [{
      //   departmentCode: '6666',
      //   departmentName: '666'
      // }]
      this.queryParam.parentCode = keys[0] || ''
      this.$refs.table.refresh(true)
    },
    checkDeptCode(rule, value, callback) {
      if (this.modalFlag === 'create') {
        validateDeptCode(value).then(v => {
          if (v) {
            callback()
            return
          }
          callback(new Error('部门编码已存在'))
        })
      } else {
        callback()
      }
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.formVisible = true
      this.$nextTick(_ => {
        this.form.resetFields()
      })
    },
    handleOk() {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = false
          if (this.modalFlag === 'create') {
            const formData = { ...values, parentCode: this.queryParam.parentCode || '-1' }
            createDept(formData).then(v => {
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: '创建成功.'
              })
              this.currentNodeData.children.push(v)
              this.$refs.table.refresh(true)
            })
          } else {
            updateDept(values.departmentCode, values).then(v => {
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: '编辑成功.'
              })
              this.$refs.table.refresh(true)
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.$nextTick(_ => {
        const formData = {}
        Object.keys(this.form.formItems).forEach(k => {
          formData[k] = record[k]
        })
        this.form.setFieldsValue(formData)
      })
    },
    handleDelete(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除该条信息？',
        onOk: () => {
          const code = record.departmentCode
          deleteDept([code]).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.currentNodeData.children = this.currentNodeData.children.filter(v => {
              return v.departmentCode !== code
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    }
  }
}
</script>
